<template>
	<div class="invitePage">
		<div class="banner_wrap">
			<div class="banner">
				<h1>参与推广，获得收入</h1>
				<p>邀请好友成功付费，可以获得5%佣金返现</p>
				<a-button>推荐给好友</a-button>
			</div>
			<div class="wallet">
				<div class="left">
					<div>
						<img
							src="../../../assets/img/fee/icon_wallet.png"
							width="40"
							height="40"
							alt="" />
					</div>
					<div>
						<b>{{ invite_list.length }}</b>
						<p>累计邀请好友</p>
					</div>
					<div>
						<b>{{ rebate }}</b>
						<p>返佣金额（元）</p>
					</div>
				</div>
				<div class="right" @click="$router.push('/manage/fee/cash')">
					立即提现
				</div>
			</div>
		</div>
		<div class="promotion">
			<h2>推广链接/邀请码</h2>
			<p>分享邀请链接或邀请码，推荐用户购买快洋淘套餐以获取奖励。</p>
			<div class="link">
				<div class="left">
					<span>邀请链接:</span>
					<div>
						<div>
							https://www.yangtao.com?invite={{ invite_code }}
						</div>
						<div class="btn_copy" @click="copyInvite('link')">
							复制
						</div>
					</div>
				</div>
				<div class="right">
					<span>邀请码: </span>
					<div>
						<div>{{ invite_code }}</div>
						<div class="btn_copy" @click="copyInvite('code')">
							复制
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rule">
			<h2>活动规则</h2>
			<ul class="rules">
				<li>
					通过你的邀请链接注册快洋淘浏览器专业版的好友，好友首次购买设备后，按照设备金额的5%进行分佣；
				</li>
				<li>
					佣金累计达到100元后方可进行提现，目前支持提现到账户余额；
				</li>
				<li>使用优惠券购买设备不在返佣范围之内；</li>
				<li>本活动最终解释权归快洋淘跨境浏览器所有；</li>
			</ul>
		</div>
		<div class="invite_record">
			<h2>邀请记录</h2>
			<a-table
				:columns="columns"
				:data-source="invite_list"
				:row-key="(a, b) => b.toString()"
				:pagination="false">
				<span slot="status" slot-scope="status">{{
					status | statusFilter
				}}</span>
			</a-table>
			<a-pagination
				@change="pageChange"
				:total="total"
				:pageSize="10"></a-pagination>
		</div>
	</div>
</template>

<script>
import { user_info } from "@/api/login";
import { copy } from "iclipboard";
import { getInviteList_api } from "@/api/invite";
const columns = [
	{
		title: "注册时间",
		dataIndex: "register_time",
	},
	{
		title: "注册公司名",
		dataIndex: "register_business",
	},
	{
		title: "首次下单时间",
		dataIndex: "pay_time",
	},
	{
		title: "返佣金额（元）",
		dataIndex: "rebate_money",
	},
	{
		title: "状态",
		dataIndex: "status",
		scopedSlots: { customRender: "status" },
	},
];
export default {
	name: "InvitePage",
	components: {},
	data() {
		return {
			invite_code: "",
			columns,
			invite_list: [],
			page: 1,
			total: 0,
			rebate: 0,
		};
	},
	methods: {
		copyInvite(type) {
			let str;
			if (type == "link") {
				str = "https://www.yangtao.com?invite=" + this.invite_code;
			} else if (type == "code") {
				str = this.invite_code;
			}
			if (copy(str)) {
				this.$message.success("复制成功");
			} else {
				this.$message.error("复制失败，请重试");
			}
		},
		getInviteList() {
			getInviteList_api({
				page: this.page,
				pagesize: 10,
			}).then(res => {
				if (res.data.code == 200) {
					this.invite_list = res.data.data.list;
					this.total = res.data.data.total;
				}
			});
		},
		pageChange(e) {
			this.page = e;
			this.getInviteList();
		},
	},
	mounted() {
		user_info().then(res => {
			if (res.data.code == 200) {
				this.invite_code = res.data.data.member.invite;
				this.rebate = res.data.data.member.rebate;
			}
		});
		this.getInviteList();
	},
	filters: {
		statusFilter(value) {
			switch (value) {
				case 1:
					return "已到账";
			}
		},
	},
};
</script>
<style lang="less" scoped>
.invitePage {
	.banner_wrap {
		height: 320px;
		background-color: #3960f4;
		background-image: url("../../../assets/img/fee/invite_bg.png");
		background-size: 100% 100%;
		.banner {
			width: 1100px;
			margin: 0 auto;
			height: 290px;
			box-sizing: border-box;
			padding-top: 70px;

			h1 {
				font-size: 44px;
				font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
				font-weight: normal;
				color: #ffffff;
				line-height: 60px;
				margin: 0;
			}
			p {
				font-size: 18px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.8);
				line-height: 25px;
				margin-top: 12px;
			}
			.ant-btn {
				width: 140px;
				height: 50px;
				background: #ff7a00;
				border-radius: 6px;
				margin-top: 36px;
				border: none;
				font-size: 18px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #ffffff;
				&:after {
					box-shadow: 0 0 0 0 #ff7a00;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
		.wallet {
			width: 1100px;
			height: 112px;
			margin: 0 auto;
			box-sizing: border-box;
			background-color: #ffffff;
			background-image: url("../../../assets/img/fee/wallet_bg.png");
			background-size: 100% 100%;
			box-shadow: 0px 2px 10px 0px rgba(139, 127, 126, 0.12);
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 38px 0 18px;
			.left {
				display: flex;
				div {
					width: 166px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					box-sizing: border-box;
					padding-left: 20px;
					b {
						font-size: 24px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #ff7a00;
						line-height: 33px;
					}
					p {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #878fa7;
						line-height: 20px;
					}
					& + div {
						border-left: 1px solid #ebeef7;
					}
				}
			}
			.right {
				width: 120px;
				height: 46px;
				background: #ff7a00;
				border-radius: 4px;
				line-height: 46px;
				text-align: center;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #ffffff;
				cursor: pointer;
				transition: all 0.3s;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.promotion {
		width: 1100px;
		margin: 130px auto 0;
		& > h2 {
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #252e3d;
			line-height: 25px;
		}
		& > p {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 20px;
			margin-top: 10px;
		}
		.link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;
			& > div {
				display: flex;
				align-items: center;
				span {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #252e3d;
					line-height: 22px;
				}
				& > div {
					display: flex;
					width: 529px;
					height: 46px;
					background: #f2f3f3;
					border-radius: 2px;
					line-height: 46px;
					box-sizing: border-box;
					padding-left: 20px;
					margin-left: 12px;
					div {
						flex-grow: 1;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #374567;
						&.btn_copy {
							flex: 0 0 70px;
							background: #4c84ff;
							border-radius: 2px;
							font-size: 16px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
							color: #ffffff;
							text-align: center;
							cursor: pointer;
							transition: all 0.3s;
						}
					}
				}
				&.right {
					& > div {
						width: 372px;
					}
				}
			}
		}
	}
	.rule {
		width: 1100px;
		margin: 40px auto;
		h2 {
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #252e3d;
			line-height: 25px;
		}
		.rules {
			padding-left: 20px;
			li {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #999999;
				line-height: 20px;
				list-style-type: disc;
				& + li {
					margin-top: 8px;
				}
			}
		}
	}
	.invite_record {
		width: 1100px;
		margin: 40px auto;
		.ant-pagination {
			margin-top: 30px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			::v-deep .ant-pagination-item-active {
				background-color: #4c84ff;
				a {
					color: #fff;
				}
			}
		}
	}
}
</style>
